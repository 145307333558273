import { combineReducers } from '@reduxjs/toolkit';
import dashboardReducer from './Dashboard/dashboardSlice';
import trackerReducer from './Tracker/trackerSlice';
import overTimeReducer from './OverTimeList/overTimeSlice';
import memberShipSlice from './MemberShip/memberShipSlice';
import userReducer from './userSlice';
import notificationReducer from './Notification/userNotificationsSlice';
import checkListReducer from './CheckList/checklistSlice';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  timeTracker: trackerReducer,
  overTime: overTimeReducer,
  user: userReducer,
  membership: memberShipSlice,
  notifications: notificationReducer,
  checklistitems: checkListReducer,

});

export default rootReducer;
