import React from 'react';

const StateIcon = ({ isActive, width, height }) => {
    const fillColor = isActive ? '#fff' : '#666';

    return (
        <svg width={width} height={height} viewBox="0 0 26 29" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
            <path d="M0 29C0 26.8783 0.842855 24.8434 2.34315 23.3431C3.84344 21.8429 5.87827 21 8 21C10.1217 21 12.1566 21.8429 13.6569 23.3431C15.1571 24.8434 16 26.8783 16 29H0ZM8 20C4.685 20 2 17.315 2 14C2 10.685 4.685 8 8 8C11.315 8 14 10.685 14 14C14 17.315 11.315 20 8 20Z" fill="#666666" />
            <path d="M16.722 11.1362L13.6362 8.04969L14.6646 7.02132L16.722 9.07805L20.8355 4.96387L21.8646 5.99296L16.722 11.1362Z" fill="#666666" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 8C10 3.58182 13.5818 0 18 0C22.4182 0 26 3.58182 26 8C26 12.4182 22.4182 16 18 16C13.5818 16 10 12.4182 10 8ZM18 14.5455C17.1404 14.5455 16.2893 14.3762 15.4952 14.0472C14.701 13.7183 13.9795 13.2361 13.3717 12.6283C12.7639 12.0205 12.2817 11.299 11.9528 10.5048C11.6238 9.71071 11.4545 8.85956 11.4545 8C11.4545 7.14044 11.6238 6.28929 11.9528 5.49516C12.2817 4.70103 12.7639 3.97947 13.3717 3.37166C13.9795 2.76386 14.701 2.28173 15.4952 1.95279C16.2893 1.62385 17.1404 1.45455 18 1.45455C19.736 1.45455 21.4008 2.14415 22.6283 3.37166C23.8558 4.59918 24.5455 6.26404 24.5455 8C24.5455 9.73596 23.8558 11.4008 22.6283 12.6283C21.4008 13.8558 19.736 14.5455 18 14.5455Z" fill="#666666" />
        </svg>
    );
};
export default StateIcon;