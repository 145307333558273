import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BaseUrl = process.env.REACT_APP_BASH_URL;

const options = {
    Authorization: `Bearer ${localStorage.getItem("shinpay-vendor-token")}`,
    "Content-Type": "application/json",
  };

export const fetchSubscriptionPlans = createAsyncThunk(
    'subscriptions/fetchSubscriptionPlans',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BaseUrl}/vendor/subscription-plans`, { headers: options });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const memberShipSlice = createSlice({
    name: 'subscriptions',
    initialState: {
        plans: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptionPlans.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
                state.status = 'succeeded';
                console.log('action.payload :  ' , action.payload)
                state.plans = action.payload;
            })
            .addCase(fetchSubscriptionPlans.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default memberShipSlice.reducer;
