import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";

import ListItemButton from "@mui/material/ListItemButton";
import {
  AppBar,
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  colors,
  Badge,
} from "@mui/material";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./Home";
import { MdDashboard } from "react-icons/md";
import { FaCcMastercard } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa6";
import { FiLogOut } from "react-icons/fi";
import Staff from "./Staff";
import { MdOutlineAccountBalance } from "react-icons/md";
import Profile from "./Profile";
import HeaderTime from "../common/HeaderTime";
import { grey } from "@mui/material/colors";
import Header from "../common/Header";
import { FaTasks } from "react-icons/fa";
import Jobs from "./Jobs";
import EmpDetails from "../view/EmpDetails";
import { TbBriefcase } from "react-icons/tb";
import Calendar from "./Calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import FeedBack from "../components/FeedBack";
import ExportAllEmployeesData from "./ExportAllEmployeesData";
import { MdManageHistory } from "react-icons/md";
import { MdAccessTime } from "react-icons/md";
import Shift from "./Shift";
import ShiftAssign from "../view/ShiftAssign";
import State from "./State";
import { RiHome3Fill } from "react-icons/ri";
import StateIcon from "../assets/StateIcon ";
import SubscriptionPlans from "./subscriptionPlans";
import SuccessPage from "./successPage";
import NotificationList from "./NotificationList";
import NotificationIcon from "../assets/NotificationIcon";
import { useDispatch, useSelector } from "react-redux";
import { getUnreadCount } from "../store/Notification/userNotificationsSlice";
import TimeTracker from "./timeTracker";
import CheckList from "./checkList";

const drawerWidth = 250;

function Dashboard({ user }) {
  const [menu, setMenu] = useState({
    name: "Dashboard",
    active: 0,
  });
  const notificationCount = useSelector(
    (state) => state.notifications.unreadCount
  );
  const location = useLocation();
  const dispatch = useDispatch();

  const menuAtive = "rgba(16, 138, 0, 1)";
  const textActive = "rgba(255, 255, 255, 1)";

  // const menuInActive = colors.grey[200]
  const menuInActive = "rgba(255, 255, 255, 1)";
  const textInActive = "rgba(102, 102, 102, 1)";

  useEffect(() => {
    dispatch(getUnreadCount(user?._id));
  }, [user, location.pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": { width: drawerWidth, boxSizing: "border-box" },
          borderColor: "#F5F5F5",
        }}
        variant="permanent"
        anchor="left"
      >
        {/* app icon */}
        <ListItem
          disablePadding
          sx={{ background: "white", mb: 0.3, width: "100%", height: 150 }}
        >
          <ListItemButton sx={{ width: "100%", height: "100%" }}>
            <ListItemIcon
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src="./logo/sky.png"
                alt="logo"
                style={{
                  width: "160px",
                  height: "120px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        {/* dashboard */}
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <Tooltip title="Dashboard" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 0 ? menuAtive : menuInActive,
                color: menu.active === 0 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Dashboard", active: 0 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {/* <img src="./assets/menu/material-symbols-light_dashboard-rounded.svg" alt="star" style={{color: colors.grey[200], backgroundColor: colors.grey[200]}} /> */}
                  <MdDashboard
                    style={{
                      width: 25,
                      height: 25,
                      color: menu.active === 0 ? textActive : textInActive,
                    }}
                  />
                  <ListItemText
                    primary={"Dashboard"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 0 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>

        {/* Jobs */}
        <Link to={"/job"} style={{ textDecoration: "none" }}>
          <Tooltip title="Jobs" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 1 ? menuAtive : menuInActive,
                color: menu.active === 1 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Jobs", active: 1 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <TbBriefcase
                    style={{
                      width: 25,
                      height: 25,
                      color: menu.active === 1 ? textActive : textInActive,
                    }}
                  />
                  <ListItemText
                    primary={"Jobs"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 1 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>

        <Link to={"/timeTracker"} style={{ textDecoration: "none" }}>
          <Tooltip title="Time Tracker" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 10 ? menuAtive : menuInActive,
                color: menu.active === 10 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Employee Time Tracker", active: 10 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <MdAccessTime
                    style={{
                      width: 25,
                      height: 25,
                      color: menu.active === 10 ? textActive : textInActive,
                    }}
                  />
                  <ListItemText
                    primary={"Time Tracker"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 10 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>
        <Link to={"/checkList"} style={{ textDecoration: "none" }}>
          <Tooltip title="CheckList" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 11 ? menuAtive : menuInActive,
                color: menu.active === 11 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Check List", active: 11 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <MdAccessTime
                    style={{
                      width: 25,
                      height: 25,
                      color: menu.active === 11 ? textActive : textInActive,
                    }}
                  />
                  <ListItemText
                    primary={"Check-List"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 11 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>


        {/* Shift master */}
        <Link to={"/shift"} style={{ textDecoration: "none" }}>
          <Tooltip title="Shift Master" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 7 ? menuAtive : menuInActive,
                color: menu.active === 7 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Shift Master", active: 7 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <MdManageHistory
                    style={{
                      width: 25,
                      height: 25,
                      color: menu.active === 7 ? textActive : textInActive,
                    }}
                  />
                  <ListItemText
                    primary={"Shift Master"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 7 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>

        {/* state master */}

        {/* users */}
        <Link to={"/staff"} style={{ textDecoration: "none" }}>
          <Tooltip title="Employee" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 2 ? menuAtive : menuInActive,
                color: menu.active === 2 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Employee", active: 2 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <FaUsers
                    style={{
                      width: 25,
                      height: 25,
                      color: menu.active === 2 ? textActive : textInActive,
                    }}
                  />
                  <ListItemText
                    primary={"Employee"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 2 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>

        {/* calendar */}
        <Link to={"/state"} style={{ textDecoration: "none" }}>
          <Tooltip title="OT| Calculations" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 8 ? menuAtive : menuInActive,
                color: menu.active === 8 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "OT| Calculations", active: 8 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {/* <RiHome3Fill style={{ width: 25, height: 25, color: menu.active === 8 ? textActive : textInActive }} /> */}
                  {/* <img src={`/icons/state_master.svg`} alt='State Icon' style={{ width: '30px', height: '30px', color: menu.active === 8 ? textActive : textInActive, }} /> */}
                  <StateIcon
                    isActive={menu.active === 8 ? true : false}
                    width={30}
                    height={30}
                  />
                  <ListItemText
                    primary={"OT| Calculations"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 8 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>
        <Link to={"/notifications"} style={{ textDecoration: "none" }}>
          <Tooltip title="Notifications" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 9 ? menuAtive : menuInActive,
                color: menu.active === 9 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Notifications", active: 9 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {notificationCount > 0 ? (
                    <Badge
                      badgeContent={notificationCount}
                      color="error"
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <NotificationIcon
                        isActive={menu.active === 9}
                        width={26}
                        height={26}
                      />
                    </Badge>
                  ) : (
                    <NotificationIcon
                      isActive={menu.active === 9}
                      width={26}
                      height={26}
                    />
                  )}
                  <ListItemText
                    primary="Notifications"
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 9 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>

        {/* report */}
        <Link to={"/report"} style={{ textDecoration: "none" }}>
          <Tooltip title="Report" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 5 ? menuAtive : menuInActive,
                color: menu.active === 5 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Report", active: 5 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <TbReportAnalytics
                    style={{
                      width: 25,
                      height: 25,
                      color: menu.active === 5 ? textActive : textInActive,
                    }}
                  />
                  <ListItemText
                    primary={"Report"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 5 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>

        {/* profile */}
        <Link to={"/account"} style={{ textDecoration: "none" }}>
          <Tooltip title="Profile" placement="right">
            <ListItem
              disablePadding
              sx={{
                background: menu.active === 3 ? menuAtive : menuInActive,
                color: menu.active === 3 ? textActive : textInActive,
                mb: 0.3,
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setMenu({ name: "Profile", active: 3 })}
            >
              <ListItemButton sx={{ width: "100%", height: "100%" }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {/* <img src="./assets/menu/material-symbols-light_dashboard-rounded.svg" alt="star" style={{color: colors.grey[200], backgroundColor: colors.grey[200]}} /> */}
                  <MdOutlineAccountBalance
                    style={{
                      width: 25,
                      height: 25,
                      color: menu.active === 3 ? textActive : textInActive,
                    }}
                  />
                  <ListItemText
                    primary={"Account"}
                    sx={{
                      textDecoration: "none",
                      color: menu.active === 3 ? textActive : textInActive,
                      marginLeft: 1,
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 2,
          height: "100vh",
        }}
      >
        {/* <Toolbar /> */}
        {menu.active == 3 ? (
          ""
        ) : (
          <>
            {/* <HeaderTime /> */}
            <Header title={menu.name} />
          </>
        )}
        {/* <Header theme={theme} /> */}
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/job" element={<Jobs user={user} />} />

          <Route
            path="/shift"
            element={<Shift user={user} setMenu={setMenu} />}
          />
          <Route
            path="/shift/assign/:id"
            element={<ShiftAssign user={user} />}
          />

          <Route
            path="/state"
            element={<State user={user} setMenu={setMenu} />}
          />

          <Route path="/staff" element={<Staff user={user} />} />
          <Route
            path="/staff/:id"
            element={<EmpDetails setMenu={setMenu} user={user} />}
          />

          <Route path="/account" element={<Profile user={user} />} />
          <Route
            path="/notifications"
            element={<NotificationList user={user} />}
          />

          <Route path="/calendar" element={<Calendar user={user} />} />
          <Route path="/timeTracker" element={<TimeTracker user={user} />} />
          <Route path="/checkList" element={<CheckList user={user} />} />

          <Route
            path="/report"
            element={<ExportAllEmployeesData user={user} />}
          />

          <Route path="/feed/:id" element={<FeedBack user={user} />} />

          <Route path="/subscription-Plans" element={<SubscriptionPlans />} />
          <Route path="/success" element={<SuccessPage />} />

          {/* <Route path='/plan' element={<Plan pageName={name} />} />
                    <Route path='/plan/:id' element={<PlanView pageName={name} />} />
                    <Route path='/plan/add' element={<PlanAdd pageName={name} />} />
                    <Route path='/plan/edit/:id' element={<PlanAdd pageName={name} />} /> */}

          {/* <Route path='/users' element={<Users pageName={name} />} /> */}
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;
