import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const BaseUrl = process.env.REACT_APP_BASH_URL;
const options = {
    Authorization: `Bearer ${localStorage.getItem("shinpay-vendor-token")}`,
    "Content-Type": "application/json",
  };


  export const dashboardData = createAsyncThunk(
    'dashboard/list',
    async (user, thunkAPI) => {
        try {
            const response = await axios.get(`${BaseUrl}/vendor/get-count/${user?._id}`, { headers: options });
            return response.data;
        } catch (error) {
            console.log(error.message);
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        data: [],
        status: 'idle',
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(dashboardData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(dashboardData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.result;
            })
            .addCase(dashboardData.rejected, (state, action) => {
                state.status = 'failed';
                console.log('failed')
                state.error = action.payload;
            });


    },
});
export default dashboardSlice.reducer;
