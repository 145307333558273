import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/custom.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  FormGroup,
  Label,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Grid, MenuItem, Select, InputLabel, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import classnames from "classnames";
import { CHECKLIST } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addChecklist,
  assignChecklistToMember,
  employeeCheckLists,
  fetchChecklists,
} from "../store/CheckList/checklistSlice";
import { fetchEmployeesByVendor } from "../store/Tracker/trackerSlice";
import { timeAgo } from "../Helper/functions";

const ChecklistApp = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { itemsList, employeeChecklists, status } = useSelector(
    (state) => state.checklistitems
  );
  const { employees } = useSelector((state) => state.timeTracker);


  const [title, setTitle] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const toggle = () => setModal(!modal);
  const [employee, setEmployee] = useState("");
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const [checkedItems, setCheckedItems] = useState([]);

  const handleChecklistSelect = (checklistId) => {
    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.includes(checklistId)
        ? prevCheckedItems.filter((id) => id !== checklistId) // Deselect
        : [...prevCheckedItems, checklistId] // Select
    );
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(fetchChecklists());
    dispatch(employeeCheckLists());
    
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchEmployeesByVendor(user?._id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    console.log("itemsList : ", itemsList);
  }, [itemsList]);

  const handleSubmit = () => {
    console.log(checkedItems);
    console.log(title);
    dispatch(addChecklist(title, checkedItems));
  };

  const handleEmployeeChange = (event) => {
    setEmployeeName(event.target.value);
  };

  const checklistss = [
    {
      id: 1,
      title: "Checklist 1",
      description: "Lorum ipsum content",
      employee: "Marketing Specialist",
      location: "Levice, Nitra",
      createdOn: "Apr, 18, 2022",
      status: "Open",
    },
    {
      id: 2,
      title: "Checklist 2",
      description: "Lorum ipsum content",
      employee: "HR Specialist",
      location: "Bratislava, Slovakia",
      createdOn: "May, 12, 2022",
      status: "Open",
    },
    {
      id: 3,
      title: "Checklist 3",
      description: "Lorum ipsum content",
      employee: "Sales Specialist",
      location: "Košice, Slovakia",
      createdOn: "June, 2, 2022",
      status: "Open",
    },
    // Add more checklist items as needed
  ];
  const candidates = [
    {
      id: 1,
      name: "Robert William",
      location: "Levice, Nitra",
      jobOpening: "Marketing Specialist",
      status: "New",
      updateTime: "Updated in 1 month",
      rating: 4,
    },
    {
      id: 2,
      name: "John Doe",
      location: "Bratislava, Slovakia",
      jobOpening: "HR Specialist",
      status: "New",
      updateTime: "Updated in 1 month",
      rating: 4,
    },
    {
      id: 3,
      name: "Jane Smith",
      location: "Košice, Slovakia",
      jobOpening: "Sales Specialist",
      status: "New",
      updateTime: "Updated in 1 month",
      rating: 4,
    },
    // Add more candidates if needed
  ];

  const renderRatingStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <svg
          key={i}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill={i < rating ? "#ffc107" : "#e4e5e9"}
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443a.5.5 0 0 1-.757-.545l1.05-4.73L.173 6.765a.5.5 0 0 1 .284-.88l4.898-.696 2.082-4.093a.5.5 0 0 1 .896 0l2.082 4.093 4.898.696a.5.5 0 0 1 .284.88l-3.732 3.403 1.05 4.73a.5.5 0 0 1-.757.545L8 13.187l-4.389 2.256z" />
        </svg>
      );
    }
    return stars;
  };

  const handleAssignChecklist = (e) => {
    e.preventDefault();
    if (employee && checkedItems) {
        dispatch(assignChecklistToMember({
            employeeId: employee,
            checklistId: checkedItems,
        }));
        setModal(!modal);
    }
};

  return (
    <Container className="mt-5 mx-2">
      <Row className="row bg-white border rounded">
        <Col md="12" className="px-0">
          <Nav tabs className="mb-0 checkListMenu">
            <div>
              <p
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTab("1");
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: "#000",
                  padding: "1rem 2rem",
                  fornSize: "17px",
                  marginBottom: "0px",
                }}
              >
                Create Checklist
              </p>
            </div>
            <div>
              <p
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggleTab("3");
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: "#000",
                  padding: "1rem 2rem",
                  fornSize: "17px",
                  marginBottom: "0px",
                }}
              >
              Onboarding Candidate
              </p>
            </div>
          </Nav>

          <TabContent activeTab={activeTab} className="my-3">
            <TabPane tabId="1">
              <div className="row justify-content-center">
                <div className="col-md-9 border rounded p-4 m-4">
                  <div className="px-5">
                    <FormGroup>
                      <Label for="title">Title</Label>
                      <Input
                        type="text"
                        id="title"
                        placeholder="Enter title"
                        style={{
                          padding: "0.5rem",
                          border: "1px solid #ced4da",
                          borderRadius: "0.25rem",
                          fontSize: "1rem",
                        }}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </FormGroup>
                    <Button
                      color="success"
                      className="mb-3"
                      onClick={() => {
                        handleSubmit();
                      }}
                      style={{ backgroundColor: "#28a745" }}
                    >
                      Create
                    </Button>

                    <FormGroup tag="fieldset">
                      <h5 className="pt-2">List of Checklists</h5>
                      {itemsList.length &&
                        itemsList?.map((item, index) => {
                          return (
                            <FormGroup check className="pt-2" key={index}>
                              <Label check className="custom-checkbox-label">
                                <Input
                                  type="checkbox"
                                  name="checklist"
                                  value={index}
                                  onChange={() => handleChecklistSelect(index)}
                                  className="custom-checkbox"
                                />
                                <span
                                  className={`custom-checkbox-icon checked`}
                                ></span>
                                {item.title}
                              </Label>
                            </FormGroup>
                          );
                        })}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="d-flex justify-content-between my-4 px-3">
               
              <p className="text-success cursor-pointer" onClick={toggle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="green"
                    viewBox="0 0 512 512"
                  >
                    {" "}
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                  </svg>{" "}
                  Add Employee
                </p>
                 <p className="text-secondary cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="lightgray"
                    viewBox="0 0 512 512"
                  >
                    {" "}
                    <path d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
                  </svg>
                 <span className="ml-2"> {employeeChecklists.length} Employees</span>
                </p>
              </div>
              <Table className="overflow-hidden mb-0">
                <thead>
                  <tr>
                    <th style={{ paddingLeft: "40px" }}>Employee Info</th>
                    <th>Job Opening</th>
                    <th>Status</th>
                    <th>Rating</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {employeeChecklists?.length ? (
                  employeeChecklists?.map((candidate, idx) => (
                    <tr key={idx} style={{ verticalAlign: "middle" }}>
                      <td style={{ height: "90px", paddingLeft: "40px" }}>
                        <div>
                          <strong>{candidate.employee.firstName}</strong>
                        </div>
                        <div>
                          <strong>{candidate.employee.address}</strong>
                        </div>
                      </td>
                      <td className="position-relative">
                        <Button className="btn checkListBtn"
                        onClick={() =>{
                        }}>
                          View Checklist
                        </Button>
                        <span className="customToolTip">{candidate.checklists.length}</span>
                      </td>

                      <td align="left">
                        <div>
                          <div>
                            <strong>{candidate.checklists[0].status}</strong>
                          </div>
                          <div>{timeAgo(candidate.checklists[0].checklists.createdAt)}</div>
                        </div>
                      </td>
                      <td>{renderRatingStars(candidate.checklists[0].checklists?.rating ?? 0)}</td>
                      <td align="left">
                        <div className="d-flex gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="green"
                            viewBox="0 0 512 512"
                          >
                            <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1 0 32c0 8.8 7.2 16 16 16l32 0zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="red"
                            viewBox="0 0 448 512"
                          >
                            <path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-4 py-4 text-center" colSpan="5">
                      <p className="mb-0">No Record Found.</p>
                    </td>
                  </tr>
                )}
                </tbody>
              </Table>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <Modal
        center
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "750px" }}
        className="addEmployee"
      >
        <ModalHeader toggle={toggle}>Add Employee</ModalHeader>
        <ModalBody className="d-flex   p-4" style={{ minHeight: "350px" }}>
          <FormGroup className="w-50" style={{ border: "1px solid lightgray" }}>
            <Label for="checklistSelect" className="customHeading">
              <strong>Select Checklist</strong>
            </Label>
            <div style={{paddingLeft:'20px'}}>
            {itemsList.length &&
              itemsList?.map((checklist) => (
                <FormControlLabel   
                key={checklist._id}
                control={
                  <Checkbox
                    checked={checkedItems.includes(checklist._id)}
                    onChange={() => handleChecklistSelect(checklist._id)}
                    sx={{
                      color: checkedItems.includes(checklist._id) ? '#4caf50' : 'default',
                      '&.Mui-checked': {
                        color: '#4caf50',
                      },
                      '& .MuiSvgIcon-root': {
                        borderRadius: '50%', // Make checkbox round
                      },
                    }}
                  />
                }
                label={checklist.title}
                style={{
                  marginBottom: '10px',
                  color: checkedItems.includes(checklist._id) ? '#4caf50' : 'default',
                  fontWeight: checkedItems.includes(checklist._id) ? 'bold' : 'normal',
                }}
              />
              ))}
              </div>
          </FormGroup>
          <FormGroup className="w-50  px-4 ">
            <Label for="employeeName">Assign To</Label>
            <FormControl fullWidth>
            <Select
              value={employee}
              onChange={(e) => setEmployee(e.target.value)}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {employees?.map((employee) => (
                <MenuItem key={employee._id} value={employee._id}>
                  {employee.name}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </FormGroup>
        </ModalBody>
        <div className="pb-4 text-center">
          <Button color="success" className="px-5" onClick={handleAssignChecklist}>
            Submit
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default ChecklistApp;
