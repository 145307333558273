import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { MdDeleteForever } from "react-icons/md";
import MuiAlert from "@mui/material/Alert";
import axios from 'axios';
import { CiSearch } from "react-icons/ci";
import AddJob from '../popup/AddJob';
import moment from 'moment';
import DeleteComponent from '../common/DeleteComponent';
import { useSelector } from 'react-redux';
import AddShift from '../popup/AddShift';
import { MdOutlineAddAlarm } from "react-icons/md";
import { Link } from 'react-router-dom';

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const bashUrl = process.env.REACT_APP_BASH_URL;

function Shift({ setMenu }) {
    const options = { Authorization: `Bearer ${localStorage.getItem("shinpay-vendor-token")}`, "Content-Type": "application/json" };
    const user = useSelector((state) => state.user.user);

    const [staffs, setStaffs] = useState([])

    const [addEmpOpen, setAddEmpOpen] = useState(false)

    const [openDelete, setOpenDelete] = useState(false)
    const [data, setData] = useState({
        type: 'shift',
        id: '',
        title: ''
    })

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterdData, setFilterData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();
    const [loading, setLoading] = useState(true);

    // const [searchBy, setSearchBy] = useState('All')

    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = staffs?.filter((item) =>
            item?.name?.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]);


    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    /* const handleOnSelect = evt => {
        setSearchBy(evt.target.value)
    } */


    const getAllJobs = async () => {
        return await axios.get(`${bashUrl}/shift/get-all/${user?._id}`, { headers: options }).then((response) => {
            setStaffs(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
        }).catch((error) => {
            setSuccess("")
            setError(error.response.data.msg)
            setCustomVariant("error")
            // setOpen(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getAllJobs()
    }, [loading, refresh])



    return (
        <Paper sx={{ borderRadius: 0, width: '100%', ml: 1, p: 2 }}>
            <Box>
                {/* <SnackBar success={success} error={error} customVariant={customVariant} open={open} setOpen={setOpen} /> */}
                <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
                    <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
                </Snackbar>
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Box sx={{ width: '100%', height: 70, mb: 1, display: 'flex', alignItems: 'center' }} >
                    <Box sx={{ width: "40%" }}>
                        <TextField fullWidth label="Search" name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search shift by name" id="outlined-start-adornment"
                            InputProps={{ startAdornment: (<InputAdornment position="start"><CiSearch /></InputAdornment>), }}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button variant='contained' sx={{ height: 40, width: 200, bgcolor: "rgba(16, 138, 0, 1)", ":hover": { bgcolor: "rgba(16, 138, 0, 10)" } }} onClick={() => setAddEmpOpen(true)}>Add Shift</Button>
                </Box>

                {/* adding job */}
                <AddShift setOpenModale={setAddEmpOpen} openModale={addEmpOpen} user={user} setLoading={setLoading} setSuccess={setSuccess} setError={setError} setOpen={setOpen} setCustomVariant={setCustomVariant} setRefresh={setRefresh} refresh={refresh} />

                {/* deleting job */}
                <DeleteComponent setOpenModale={setOpenDelete} openModale={openDelete} user={user} setLoading={setLoading} setSuccess={setSuccess} setError={setError} setOpen={setOpen} setCustomVariant={setCustomVariant} data={data} setData={setData} setRefresh={setRefresh} refresh={refresh} />

                <TableContainer component={Paper}>
                    <Table /* aria-label='collapsible table' */>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#E4EAEE", color: 'white' }}>
                                <TableCell sx={{ color: 'black' }} >Shift Name </TableCell>
                                <TableCell sx={{ color: 'black' }} align="center">Start Time</TableCell>
                                <TableCell sx={{ color: 'black' }} align="center">End Time</TableCell>
                                <TableCell sx={{ color: 'black' }} align="center" >Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? (
                                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <TableRow key={ind} hover>
                                            {/* <TableCell >{item?._id}</TableCell> */}
                                            <TableCell >{item?.name}</TableCell>
                                            <TableCell align="center">{moment(item?.start).format('hh:mm:ss A')}</TableCell>
                                            <TableCell align="center">{moment(item?.end).format('hh:mm:ss A')}</TableCell>
                                            <TableCell align="center">
                                                <Link to={`/shift/assign/${item?._id}`} style={{ textDecoration: 'none' }} onClick={() => setMenu({ name: `Shift Assign To Employee ${item?.name}`, active: 7 })} ><Tooltip title="Assign To Employee"><IconButton color='success'><MdOutlineAddAlarm /></IconButton> </Tooltip></Link>
                                                <Tooltip title="Delete Shift"><IconButton color='error' onClick={() => { setOpenDelete(true); setData({ id: item?._id, title: item?.name, type: 'shift' }) }}> <MdDeleteForever /> </IconButton></Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography>No shift data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </Paper>
    )
}

export default Shift