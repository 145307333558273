import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Paper, List, ListItem, Divider } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/en-gb";
import { FaBell } from "react-icons/fa"; // Importing notification icon from react-icons
import {
  markAllAsRead,
  notificationList,
} from "../store/Notification/userNotificationsSlice";

dayjs.extend(relativeTime);
dayjs.locale("en-gb");

const NotificationList = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  useEffect(() => {
    dispatch(notificationList(user?._id));
    dispatch(markAllAsRead(user?._id));
  }, [user?._id]);

  useEffect(() => {
    console.log(notifications);
  }, [notifications]);

  return (
    <Box sx={{ bgcolor: "background.paper", borderRadius: 2, p: 3 }}>
      {notifications.length > 0 ? (
        <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
          <List sx={{ width: "100%" }}>
            {notifications.map((notification) => (
              <div key={notification._id}>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <FaBell
                    className="text-green-600"
                    style={{
                      width: "45px",
                      height: "45px",
                      marginRight: "16px",
                      alignSelf: "center",
                    }}
                  />
                  <Box>
                    <Typography variant="h6" component="div" gutterBottom>
                      {notification.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      {notification.body}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      color="text.secondary"
                    >
                      {dayjs(notification.sentAt).fromNow()}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider component="li" />
              </div>
            ))}
          </List>
        </Paper>
      ) : (
        <Box textAlign="center" mt={4} color="text.secondary">
          <Typography variant="h6">No notifications found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificationList;
