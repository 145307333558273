import './App.css';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import { jwtDecode } from "jwt-decode";
import SignUp from './pages/SignUp';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './store/userSlice';

function App() {
  const token = localStorage.getItem("shinpay-vendor-token");

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const date = new Date();
  const [admin, setAdmin] = useState("");




  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < date.getTime()) {
        localStorage.removeItem("shinpay-vendor-token");
        navigate("/signin");
      } else {
        setAdmin(decodedToken);
        dispatch(setUser(decodedToken.result))
        navigate("/");
      }
    } else {
      navigate("/signin");
    }
  }, [token]);
  // console.log("admin: ", admin.result);
  return (<>{token ? <Dashboard user={admin.result} /> :
    <Routes>
      <Route path='/signin' element={<SignIn />} />
      <Route path='/register' element={<SignUp />} />
    </Routes>
  }</>);
}

export default App;
